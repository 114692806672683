import React, { useState } from 'react'
import styled from 'styled-components'
import { DL, DT, DD, DH, DR } from '~/components/DataList'
import { format, addDays, isEqual, startOfWeek } from 'date-fns'
import OrthodoxYear from '~/utils/OrthodoxYear'
// import {schedule as _schedule2022} from '../data/schedule-2022.json'
import {schedule as _schedule2023} from '../data/schedule-2023.json'
import {schedule as _schedule2024} from '../data/schedule-2024.json'
import ru from 'date-fns/locale/ru'


const schedule2023 = _schedule2023.map(ent => {
  return {
    ...ent,
    date: new Date(ent.date)
  }
})

const schedule2024 = _schedule2024.map(ent => {
  return {
    ...ent,
    date: new Date(ent.date)
  }
})

const schedule = [...schedule2023, ...schedule2024]

const yearPrev = new OrthodoxYear(2023)
const year = new OrthodoxYear(2024)
const yearNext = new OrthodoxYear(2025)

const weeks = [...yearPrev.weeks(), ...year.weeks(), ...yearNext.weeks()].map((week, index) => {
  week.index = index
  for (const yday of schedule) {
    for (const wday of week.days) {
      if (isEqual(yday.date, wday.date)) {
        if (yday.day) wday.title = yday.day
        if (!wday.services) wday.services = []
        wday.services.push({
          time: yday.time,
          title: yday.title
        })
      }
    }
  }
  return week
})

const Nav = styled.div`
  display: flex;
`
const Prev = styled.div`
  width: 1em;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' /%3E%3C/svg%3E");
  &.disabled {
    background-image: none;
  }
`
const Next = styled.div`
  width: 1em;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' /%3E%3C/svg%3E");
  &.disabled {
    background-image: none;
  }
`
const Title = styled.div`
  flex: 1;
  font-size: 0.85em;
  line-height: 0.85em;
`

function searchWeek(date: Date) {
  for(const week of weeks) {
    if (isEqual(startOfWeek(week.start), startOfWeek(date))) {
      return week
    }
  }
  return null
}

const Schedule: React.FC<{date?: Date}> = ({date = new Date()}) => {
  const currentWeek = searchWeek(date)
  const [week, setWeek] = useState(currentWeek)
  if (!week) return null
  return (
    <DL>
      <DH>Расписание</DH>
      <DR bb center>
        <Nav>
          <Prev
            onClick={() =>
              setWeek(weeks[week.index - 1])
            }
          ></Prev>
          <Title>{week.title}
            <br />
            <small>{format(addDays(week.start, 1), "d.MM.yy", { locale: ru })} – {format(week.end, "d.MM.yy", { locale: ru })}</small>
          </Title>
          <Next
            onClick={() =>
              setWeek(weeks[week.index + 1])
            }
          ></Next>
        </Nav>
      </DR>
      {week.days
        .filter(day => day.services)
        .map((day, dindex) => {
          return (
            <React.Fragment key={`d${dindex}-1`}>
              <DT>
                {day.dayName}
                <br/>
                <small>{format(day.date, "d MMMM", {locale: ru})}</small>
              </DT>

              <DD>
                {day.title && (
                  <span
                    style={{
                      color: `#AC3124`,
                      fontStyle: `normal`,
                      fontSize: `.75em`,
                    }}
                  >
                    <strong>{day.title}</strong>
                    <br />
                  </span>
                )}

                {day.services.length > 0 &&
                  day.services.map((e, i) => (
                    <span key={`r2-${i}`}>
                      <time>{e.time}</time> — {e.title} <br />
                    </span>
                  ))}
              </DD>
            </React.Fragment>
          )
        })}
      <DD></DD>
    </DL>
  )
}

export default Schedule
