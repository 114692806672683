import React from 'react'
import styled from 'styled-components'
import { lightBlue1 } from '~/styling'

interface IStepIndicatorProps {
  numberOfSteps: number
  currentStep: number
}

const Boxes = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Box = styled.div`
  width: 1em;
  height: 1em;
  border: 1px solid ${lightBlue1};
  box-sizing: content-box;
  border-radius: 1px;
  margin: 0.125em;
  &.active {
    border: .25em solid ${lightBlue1};
  }
  &.completed {
    border: 1px solid ${lightBlue1};
    background: ${lightBlue1};
  }
`

const StepIndicator: React.FC<IStepIndicatorProps> = ({ currentStep, numberOfSteps }) => {
  const boxes = new Array(numberOfSteps).fill('')
  // console.log('StepIndicator', numberOfSteps, currentStep)
  return (
    <Boxes>
      {boxes.map((_, i) => (
        <Box key={`b-${i}`} className={i < currentStep ? 'completed' : i > currentStep ? '' : 'active'} />
      ))}
    </Boxes>
  )
}

export default StepIndicator