import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { bodyFontFamily, primaryColor, lightRed1, darkRed1 } from '~/styling'

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
}

const StyledButton = styled.button<IButtonProps>`
  font: 1.05em/2.3 ${bodyFontFamily};
  padding: 0.1em 0.85em;
  cursor: pointer;
  background: white;
  transition: background 0.5s ease-in-out;
  transition: color 0.2s ease-in-out;
  border-radius: 1px;
  border: none;
  &:hover {
    background: ${lightRed1};
  }
  &:active {
    background: ${darkRed1};
    color: white;
  }
  ${props =>
    props.disabled &&
    css`
      color: #aaa;
      background: #eee;
      cursor: default;
      &:hover {
        color: #aaa;
        background: #eee;
      }
    `}
`

export const Button: React.FC<IButtonProps> = ({ disabled = false, children, ...props }) => {
  return (
    <StyledButton disabled={disabled} {...props}>
      {children}
    </StyledButton>
  )
}

interface IStatedButtonProps extends IButtonProps {
  active: boolean
}

const StyledStatedButton = styled(Button)<IStatedButtonProps>`
  ${props =>
    props.active &&
    css`
      color: white;
      background: ${darkRed1};
      &:hover {
        color: white;
        background: ${darkRed1};
      }
    `}
`

export const StatedButton: React.FC<IStatedButtonProps> = ({ active, children, ...props }) => {
  return (
    <StyledStatedButton active={active} {...props}>
      {children}
    </StyledStatedButton>
  )
}

export default Button
