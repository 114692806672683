import React from 'react'
import styled from 'styled-components'
import { lightBlue1 } from '~/styling'

const SpinnverDiv = styled.div`
  margin: auto;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 10px;

  & > div {
    background-color: ${lightBlue1};
    height: 100%;
    width: 8px;
    margin: 0 1px;
    display: inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  & .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  & .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  & .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  & .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`

const Spinner: React.FC = () => {
  return (
    <SpinnverDiv>
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </SpinnverDiv>
  )
}

export default Spinner