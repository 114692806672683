import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
// import { bp } from '~/utils/styling'

const bpMinWidth = '360px'

const Figure = styled.figure`
  width: 100%;
  padding: 0;
  margin: 0;

  .rect-wrapper {
    position: relative;
    width: 100%;
    height: auto;
  }

  .red-rect {
    position: absolute;
    left: ${(348 / 683) * 100}%;
    top: ${(285 / 451.53) * 100}%;
    width: ${((405 - 348) / 683) * 100}%;
    height: ${((374 - 285) / 451.53) * 100}%;
    /* left: ${(352 / 683) * 100}%;
    top: ${(290 / 451.53) * 100}%;
    width: ${((395 - 352) / 683) * 100}%;
    height: ${((365 - 290) / 451.53) * 100}%; */
    border: 3px solid #c42727;
    border-radius: 5px;
    &::after {
      position: absolute;
      content: '1';
      left: -1em;
      top: -0.3em;
      font-size: 1.2em;
      line-height: 1.2em;
      color: #c42727;
    }
  }

  .green-rect {
    position: absolute;
    left: ${(280 / 683) * 100}%;
    top: ${(370 / 451.53) * 100}%;
    width: ${((340 - 280) / 683) * 100}%;
    height: ${((445 - 370) / 451.53) * 100}%;
    border: 3px solid #00c04a;
    border-radius: 5px;
    &::after {
      position: absolute;
      content: '2';
      left: -1em;
      top: -0.3em;
      font-size: 1em;
      line-height: 1em;
      color: #00c04a;
    }
  }
`

const FigCaption = styled.figcaption`
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: ${bpMinWidth}) {
    flex-direction: row;
  }
`

const Block = styled.div`
  position: relative;
  flex: 1 1 50%;
  background: #150e00;
  font-size: 1.25em;
  text-align: center;
  font-weight: 400;
  color: white;
  padding: 1em;
  margin: 1em 0 0;
  @media (min-width: ${bpMinWidth}) {
    font-size: 0.85em;
    margin: 1em 0.5em;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Number = styled.div<{ red?: boolean; green?: boolean }>`
  position: absolute;
  text-align: center;
  top: .9em;
  left: .9em;
  font-size: 1em;
  line-height: 1.7;
  width: 1.8em;
  height: 1.8em;
  border-radius: 1.8em;
  @media (min-width: ${bpMinWidth}) {
    top: 1em;
    left: 1em;
    font-size: 1.2em;
    line-height: 1.9;
    width: 2em;
    height: 2em;
    border-radius: 2em;
  }
  ${props =>
    props.red &&
    css`
      background: #c42727;
    `}
  ${props =>
    props.green &&
    css`
      background: #00c04a;
    `}
`

const EnterSchema: React.FC<{}> = () => {
  const data: any = useStaticQuery(graphql`
    query QueryEnterSchema {
      imgVhod1: file(relativePath: {eq: "img-6-vhod.jpg"}, sourceInstanceName: {eq: "assets"}) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imgSvNikola: file(relativePath: {eq: "icon-sv-Nikolay.png"}, sourceInstanceName: {eq: "assets"}) {
        childImageSharp {
          fixed(width: 120, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imgSvGavriil: file(relativePath: {eq: "icon-sv-Gavriil.png"}, sourceInstanceName: {eq: "assets"}) {
        childImageSharp {
          fixed(width: 120, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return <Figure>
    <div className="rect-wrapper">
      <Img fluid={data.imgVhod1.childImageSharp.fluid}></Img>
      <div className="red-rect"></div>
      <div className="green-rect"></div>
    </div>
    <FigCaption>
      <Block>
        <Number red>1</Number>
        <div>
          <Img fixed={ data.imgSvNikola.childImageSharp.fixed }></Img>
        </div>
        Верхний храм в честь святителя Николая Чудотворца. Пока еще находится на стадии строительства.<br />
        <span style={{ color: `#C42727` }}>Тут служб пока нет.</span>
      </Block>
      <Block>
        <Number green>2</Number>
        <div>
          <Img fixed={ data.imgSvGavriil.childImageSharp.fixed }></Img>
        </div>
        Вход в криптовый храм в честь мученика младенца Гавриила Белостоцого.<br/>
        <span style={{color: `#00C04A`}}>Тут сейчас проходят службы.</span>
      </Block>
    </FigCaption>

  </Figure>
}

export default EnterSchema