import React from 'react'
import styled from 'styled-components'
import { Link as _ScrollLink } from 'react-scroll'
import { linkColor } from '~/styling'

export const ScrollLink = styled(_ScrollLink)`
  &:hover {
    border-bottom: 1px dashed ${linkColor.hover};
  }
  cursor: pointer;
`

export { Element as default } from 'react-scroll'