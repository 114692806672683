import styled, { css } from 'styled-components'

export const DL = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  background: #fefdfb;
  /* border: 1px solid #f6efe1; */
`

export const DH = styled.div`
  grid-column: 1 / 3;
  border-bottom: 1px solid #f9b0af;
  text-align: center;
  min-height: 1.5em;
  line-height: 1.5em;
  padding: 0.75em 0.75em;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 400;
  color: #a40000;
`

export const DT = styled.div`
  grid-column: 1 / auto;
  min-height: 1.5em;
  line-height: 1.5em;
  padding: 2em 0.5em 0;
  text-align: right;
  font-weight: 600;
  font-size: .85em;
  position: relative;
  top: .25em;
`

export const DD = styled.div`
  grid-column: 2 / auto;
  min-height: 1.5em;
  line-height: 1.5em;
  padding: 2em 0.5em 0;
  font-size: .95em;
  font-style: italic;
  border-left: 1px solid #9fc2ee;
`

export const DR = styled.div<{ center?: boolean; bt?: boolean; bb?: boolean; nopadding?: boolean }>`
  grid-column-start: 1;
  grid-column-end: 3;
  min-height: 1.5em;
  line-height: 1.5em;
  padding: 0.5em;
  font-size: 1.1em;
  font-style: italic;
  ${props =>
    props.nopadding &&
    css`
      line-height: 0;
      padding: 0;
    `}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
  ${props =>
    props.bt &&
    css`
      border-top: 1px solid #9fc2ee;
    `}
  ${props =>
    props.bb &&
    css`
      border-bottom: 1px solid #9fc2ee;
    `}
`