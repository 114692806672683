import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '~/components/Layout'
import SEO from '~/components/SEO'
import styled from 'styled-components'
import { primaryColor, bp, headerFontFamily } from '~/styling'
import { QueryIndexPageQuery } from '~/types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import MDXLayout from '~/content/components/Layout'
import { Element } from 'react-scroll'

interface IIndexPageProps {
  path: string,
  data: QueryIndexPageQuery
}

const Article = styled.article``

const Header = styled.header`
  position: relative;
`

const Phones = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  font: 200 1.3em/1 ${headerFontFamily};
  color: ${primaryColor};
  text-shadow: 0 0 10px black;
`

const Main = styled.main`
  padding: 1em;
  background: ${primaryColor};
  min-height: 40vh;

  @media (min-width: ${bp[3].minWidth}) {
    position: relative;
    top: -50px;
    background: ${primaryColor};
    border-radius: 1em;
    z-index: 2;
  }
`

const IndexPage: React.FC<IIndexPageProps> = (...args) => {
  // console.log(args)
  const { data } = args[0]
  return (
    <Layout>
      <Article>
        <Header>
          <Img className="hide-lt-1024" fluid={data.imgTop.childImageSharp.fluid}></Img>
          <Img className="hide-gt-1024" fluid={data.imgTopLite.childImageSharp.fluid}></Img>
          <Phones>
            <small>8 029</small> 766-36-44{' '}
            <small>
              <sup>(МТС)</sup>
            </small>
            <br />
            <small>8 029</small> 377-36-44{' '}
            <small>
              <sup>(A1)</sup>
            </small>
          </Phones>
        </Header>
        <Main className="fixed-width">
          <MDXLayout>
            {data.chapters.edges
              .sort((a, b) => a.node.fileAbsolutePath.localeCompare(b.node.fileAbsolutePath))
              .map(({ node }, i) => (
                <Element name={node.frontmatter.name} key={i}>
                  <MDXRenderer>{node.body}</MDXRenderer>
                </Element>
              ))}
          </MDXLayout>
        </Main>
      </Article>
      <SEO></SEO>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query QueryIndexPage($imageQuality: Int, $contentWidth2x: Int) {
    imgTop: file(relativePath: { eq: "img-1-top.jpg" }, sourceInstanceName: { eq: "assets" }) {
      childImageSharp {
        fluid(maxWidth: $contentWidth2x, quality: $imageQuality) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTopLite: file(relativePath: { eq: "img-1-top-lite.jpg" }, sourceInstanceName: { eq: "assets" }) {
      childImageSharp {
        fluid(maxWidth: $contentWidth2x, quality: $imageQuality) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chapters: allMdx(sort: { fields: fileAbsolutePath, order: ASC }) {
      edges {
        node {
          id
          fileAbsolutePath
          body
          frontmatter {
            name
          }
        }
      }
    }
  }
`