import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { DL, DH, DT, DD, DR } from '~/components/DataList'
import BuildingHistorySlider from './BuildingHistorySlider'
import Section, { ScrollLink } from './Section'
import AbbotInfo from './AbbotInfo'
import MapView from './MapView'
import ExclamationCircle from './ExclamationCircle'
import EnterSchema from './EnterSchema'
import Schedule from './Schedule'
import DonateForm from './DonateForm-2'

const shortcodes = {
  DL,
  DH,
  DT,
  DD,
  DR,
  ScrollLink,
  Section,
  BuildingHistorySlider,
  AbbotInfo,
  MapView,
  ExclamationCircle,
  EnterSchema,
  Schedule,
  DonateForm
}

const Layout: React.FC = ({ children }) => {
  return <MDXProvider components={shortcodes}>{children}</MDXProvider>
}

export default Layout