import React, { useReducer, useContext, useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Subject, BehaviorSubject, Observable, Subscription, fromEvent, interval } from 'rxjs'
import { map, auditTime, startWith, pairwise, takeWhile, bufferCount } from 'rxjs/operators'
import { useSubject, useObservable } from '~/utils/useRxContext'
import { of, combineLatest } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'
import {
  switchMap,
  mergeMap,
  tap,
  filter,
  share,
  catchError,
  pluck,
  distinctUntilChanged,
  mapTo,
  take,
  takeUntil,
  scan,
  skipWhile
} from 'rxjs/operators'
import { lightRed1, lightBlue1, darkRed1, darkBlue1 } from '~/styling'
import {Button, StatedButton} from './Button'
import Input, { NumberInput } from './Input'
import StepIndicator from './StepIndicator'
import Stepper from './Stepper'
import { chunk, max, flatten } from 'lodash-es'
import Checkbox from './Checkbox'
import CardSvgPath from './card.svg'
import EripSvgPath from './erip.svg'
import { encode, decode } from 'js-base64'
import Scroll, { Element } from 'react-scroll'
import { api, useApiContext } from '~/providers'
import { useAppContext, AppEvents } from '~/providers'
import Spinner from './Spinner'
import {isBrowser} from '~/utils'

import CardWhiteSvgPath from './card-white.svg'
import EripWhiteSvgPath from './erip-white.svg'
import CardTypesSvgPath from './cards-types.svg'
import SuccessSvgPath from './success.svg'
import ErrorSvgPath from './error.svg'

const API_BASE = 'https://nikolahramvlr.by/api'
// const API_BASE = 'http://127.0.0.1:3000/api'

export const Form = styled.section`
  display: grid;
  width: 100%;
  /* overflow: hidden; */
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: minmax(1.5em, auto) 1fr 4em;
  grid-template-areas:
    'header header header'
    'body body body'
    'prev progress next';
  background: #fefdfb;
`

export const FormHeader = styled.header`
  grid-area: header;
  border-bottom: 1px solid ${lightBlue1};
  text-align: center;
  min-height: 1.5em;
  line-height: 1.5em;
  padding: 0.75em 0.75em;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 400;
  color: #a40000;
  align-items: center;
`

export const FormFooter = styled.div`
  border-top: 1px solid ${lightBlue1};
  display: flex;
  padding: .5em;
  flex-flow: row nowrap;
  align-items: stretch;
`

export const FormPrev = styled(FormFooter)`
  grid-area: prev;
  text-align: center;
  justify-content: flex-start;
`

export const FormProgress = styled(FormFooter)`
  grid-area: progress;
`

export const FormNext = styled(FormFooter)`
  grid-area: next;
  text-align: center;
  justify-content: flex-end;
`

export const FormBody = styled(Stepper)`
  grid-area: body;
  font-size: 1em;
`

const Step = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  min-height: 20em;
  align-items: justify;
  p {
    text-align: center;
  }
`

const Table = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(3, minmax(3em, auto));
  border-top: 1px solid ${lightRed1};
  border-left: 1px solid ${lightRed1};
  margin: 1em;
  user-select: none;
`

const TableCell = styled.div`
  box-sizing: border-box;
  border-right: 1px solid ${lightRed1};
  border-bottom: 1px solid ${lightRed1};
  user-select: none;
`

const TableButton = styled(StatedButton)`
  width: 100%;
  text-align: center;
  padding: .75em 0;
`

const StepButton = styled(Button)<{ visible?: boolean }>`
    padding: 0 .5em;
    text-align: inherit;
    min-width: 6em;
    ${props =>
      props.visible === false &&
      css`
        visibility: hidden;
      `};
`

const PaymentButtonGroup = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5em;
`

const PaymentButton = styled(StatedButton)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${lightRed1};
  &:not(:first-child) {
    border-top: none;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
`
const Space = styled.div`
  flex: 1;
`

const L = styled.span`
  font-style: italic;
  user-select: none;
  font-size: 1em;
`

const P = styled.p`
  font-style: italic;
  user-select: none;
  font-size: 1em;
`

interface IDonateFormAction {
  type: string
  data?: any
}

const amountButtons: [string, number][] = [
  ['btn-1', 5],
  ['btn-2', 10],
  ['btn-3', 20],
  ['btn-4', 50],
  ['btn-5', 100],
  ['btn-6', 200]
]

const stepToProgress = new Map<number, number>([
  [0, 0],
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 3],
  [5, 4],
  [6, 4],
])

enum STEPS {
  AMOUNT = 0,
  NAME = 1,
  METHOD = 2,
  CARD = 3,
  ERIP = 4,
  ERROR = 5,
  SUCCESS = 6,
}

const maxProgress = max(flatten(Array.from(stepToProgress).map(v => v.slice(1))))

const paymentMethods: [string, string, string, string][] = [
  ['card', 'Банковской карточкой', CardSvgPath, CardWhiteSvgPath],
  ['erip', 'По системе ЕРИП', EripSvgPath, EripWhiteSvgPath]
]

interface EPWebInvoiceRequest {
  amount: number
  name?: string
  isOrthodox?: boolean
  successUrl: string
  failUrl: string
}

interface EPEripInvoiceRequest {
  amount: number
  name?: string
  isOrthodox?: boolean
}

class DonationContext {
  //* STEP *//
  protected _step$ = new BehaviorSubject<number>(STEPS.AMOUNT)
  public step$ = this._step$.pipe(share())
  public get step() {
    return this._step$.getValue()
  }

  //* AMOUNT *//
  public amount$ = new BehaviorSubject<number | null>(null)
  public get amount() {
    return this.amount$.getValue()
  }

  //* PAYMENT METHOD *//
  public paymentMethod$ = new BehaviorSubject<string | null>(null)
  public get paymentMethod() {
    return this.paymentMethod$.getValue()
  }

  public name$ = new BehaviorSubject<string>('')
  public get name() {
    return this.name$.getValue()
  }

  public isOrthodox$ = new BehaviorSubject<boolean>(true)
  public get isOrthodox() {
    return this.isOrthodox$.getValue()
  }

  protected _prevDisabledObs: Observable<boolean>[] = [
    of(true),
    of(false),
    of(false),
    of(false),
    of(false),
    of(false),
    of(false)
  ]

  protected _nextDisabledObs: Observable<boolean>[] = [
    this.amount$.pipe(map(v => !v || v === 0)),
    of(false),
    this.paymentMethod$.pipe(map(v => !(v === 'card' || v === 'erip'))),
    of(false),
    of(false),
    of(false),
    of(false)
  ]

  protected _prevCaptionObs: Observable<string>[] = [
    of(''),
    of('← Назад'),
    of('← Назад'),
    of('← Назад'),
    of('← Назад'),
    of(''),
    of('')
  ]

  protected _nextCaptionObs: Observable<string>[] = [
    of('Далее →'),
    this.name$.pipe(map(v => (v ? 'Далее →' : 'Пропустить →'))),
    of('Далее →'),
    of('Перейти →'),
    of(''),
    of('Еще раз'),
    of('Слава Богу!')
  ]

  public nextDisabled: boolean
  public nextDisabled$ = this._step$.pipe(
    mergeMap(s => this._nextDisabledObs[s]),
    share()
  )
  public prevDisabled: boolean
  public prevDisabled$ = this._step$.pipe(
    mergeMap(s => this._prevDisabledObs[s]),
    share()
  )

  public checkboxVisible: boolean
  public checkboxVisible$ = this.name$.pipe(
    map(v => !!v),
    share()
  )

  public nextCaption: string
  public nextCaption$ = this._step$.pipe(
    mergeMap(s => this._nextCaptionObs[s]),
    share()
  )
  public prevCaption: string
  public prevCaption$ = this.step$.pipe(mergeMap(s => this._prevCaptionObs[s]))

  public useHashState = false

  public data$ = new Subject<EPWebInvoiceRequest>()
  public loading$ = new BehaviorSubject<boolean>(false)
  public get loading() {
    return this.loading$.getValue()
  }
  public formUrl: string | null = null
  public formUrl$ = this.data$.pipe(
    switchMap(data => {
      this.loading$.next(true)
      return fromFetch(`${API_BASE}/invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          // 'X-Access-Token': api.token.valueOf()
        },
        body: JSON.stringify(data),
        selector: response => response.text()
      })
    }),
    catchError(err => {
      console.error(err)
      this.loading$.next(false)
      this._step$.next(STEPS.ERROR)
      return of(null)
    }),
    tap(v => this.loading$.next(false)),
    share()
  )

  public eripData$ = new Subject<EPEripInvoiceRequest>()
  public qrcode: string | null = null
  public qrcode$ = this.eripData$.pipe(
    switchMap(data => {
      this.loading$.next(true)
      return fromFetch(`${API_BASE}/erip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          // 'X-Access-Token': api.token.valueOf()
        },
        body: JSON.stringify(data),
        selector: response => response.text()
      })
    }),
    catchError(err => {
      console.error(err)
      this.loading$.next(false)
      this._step$.next(STEPS.ERROR)
      return of(null)
    }),
    tap(v => this.loading$.next(false)),
    share()
  )

  //* CONSTRUCTOR *//
  constructor() {
    this.prevDisabled$.subscribe(v => (this.prevDisabled = v))
    this.nextDisabled$.subscribe(v => (this.nextDisabled = v))
    this.prevCaption$.subscribe(v => (this.prevCaption = v))
    this.nextCaption$.subscribe(v => (this.nextCaption = v))
    this.checkboxVisible$.subscribe(v => (this.checkboxVisible = v))
    this.formUrl$.subscribe(v => (this.formUrl = v))
    this.qrcode$.subscribe(v => (this.qrcode = v))
    let dfs
    if (isBrowser() && (dfs = window.location.hash)) {
      const str = decode(dfs)
      try {
        const data = JSON.parse(str) as any
        this.amount$.next(data.a)
        this.name$.next(data.n || '')
        this.isOrthodox$.next(typeof data.o === 'number' ? (data.o > 0 ? true : false) : true)
        this.paymentMethod$.next(data.p)
        this._step$.next(data.s)
        this.useHashState = true
      } catch (error) {}
    } else if (isBrowser() && (dfs = localStorage.getItem('dfs'))) {
      const data = JSON.parse(decode(dfs)) as any
      this.amount$.next(data.a)
      this.name$.next(data.n)
      this.isOrthodox$.next(data.o > 0 ? true : false)
      this.paymentMethod$.next(data.p)
    }

    combineLatest([this.amount$, this.name$, this.isOrthodox$, this.paymentMethod$])
      .pipe(
        map(([amount, name, isOrthodox, paymentMethod]) => {
          return { a: amount, n: name, o: isOrthodox ? 1 : 0, p: paymentMethod }
        }),
        map(v => {
          return encode(JSON.stringify(v))
        })
      )
      .subscribe(v => {
        isBrowser() && localStorage.setItem('dfs', v)
      })
  }

  //* REDUCTOR *//
  dispatch = (action: IDonateFormAction) => {
    switch (action.type) {
      case 'NEXT_CLICK':
        switch (this.step) {
          case STEPS.AMOUNT:
            this._step$.next(STEPS.NAME)
            break
          case STEPS.NAME:
            this._step$.next(STEPS.METHOD)
            break
          case STEPS.METHOD:
            if (this.paymentMethod === 'card') {
              this._step$.next(STEPS.CARD)
              const { amount, name, isOrthodox, step, paymentMethod } = this
              if (name) {
                const successUrl =
                  `${location.origin}/#` +
                  encode(
                    JSON.stringify({
                      a: amount,
                      n: name,
                      o: isOrthodox ? 1 : 0,
                      s: STEPS.SUCCESS,
                      p: paymentMethod
                    })
                  )
                const failUrl =
                  `${location.origin}/#` +
                  encode(
                    JSON.stringify({
                      a: amount,
                      n: name,
                      o: isOrthodox ? 1 : 0,
                      s: STEPS.ERROR,
                      p: paymentMethod
                    })
                  )
                this.data$.next({ amount, name, isOrthodox, successUrl, failUrl })
              } else {
                const successUrl =
                  `${location.origin}/#` +
                  encode(
                    JSON.stringify({
                      a: amount,
                      s: STEPS.SUCCESS,
                      p: paymentMethod
                    })
                  )
                const failUrl =
                  `${location.origin}/#` +
                  encode(
                    JSON.stringify({
                      a: amount,
                      s: STEPS.ERROR,
                      p: paymentMethod
                    })
                  )
                this.data$.next({ amount, successUrl, failUrl })
              }
            } else if (this.paymentMethod === 'erip') {
              this._step$.next(STEPS.ERIP)
              const { amount, name, isOrthodox, step, paymentMethod } = this
              this.eripData$.next({ amount })
            }
            break
          case STEPS.CARD:
            window.location.href = this.formUrl
            // this._step$.next(STEPS.SUCCESS)
            break
          case STEPS.ERIP:
            this._step$.next(STEPS.SUCCESS)
            break
          case STEPS.ERROR:
            this._step$.next(STEPS.AMOUNT)
            break
          case STEPS.SUCCESS:
            this._step$.next(STEPS.AMOUNT)
            break
        }
        break
      case 'PREV_CLICK':
        switch (this.step) {
          case STEPS.NAME:
            this._step$.next(STEPS.AMOUNT)
            break
          case STEPS.METHOD:
            this._step$.next(STEPS.NAME)
            break
          case STEPS.CARD:
            this._step$.next(STEPS.METHOD)

            break
          case STEPS.ERIP:
            this._step$.next(STEPS.METHOD)
            break
        }
        break
      case 'GO_CARD_FORM':
        break
      default:
        break
    }
  }
}

const donationContext = React.createContext<DonationContext>(new DonationContext())

const DonateForm: React.FC = () => {
  if (!isBrowser()) return <></>

  const ctx = useContext(donationContext)
  const apiCtx = useApiContext()
  const [step] = useObservable<number>(ctx.step$, ctx.step)
  const [amount, setAmount] = useSubject<number|null>(ctx.amount$, ctx.amount)
  const [paymentMethod, setPaymentMethod] = useSubject<string|null>(ctx.paymentMethod$, ctx.paymentMethod)
  const [name, setName] = useSubject<string>(ctx.name$, ctx.name)
  const [isOrthodox, setIsOrthodox] = useSubject<boolean>(ctx.isOrthodox$, ctx.isOrthodox)
  const [prevDisabled] = useObservable<boolean>(ctx.prevDisabled$, ctx.prevDisabled)
  const [nextDisabled] = useObservable<boolean>(ctx.nextDisabled$, ctx.nextDisabled)
  const [prevCaption] = useObservable<string>(ctx.prevCaption$, ctx.prevCaption)
  const [nextCaption] = useObservable<string>(ctx.nextCaption$, ctx.nextCaption)
  const [checkboxVisible] = useObservable<boolean>(ctx.checkboxVisible$, ctx.checkboxVisible)
  const [apiAvailable] = useObservable<boolean>(apiCtx.apiAvailable$.pipe(distinctUntilChanged()), apiCtx.apiAvailable$.value)

  const [loading] = useObservable<boolean>(ctx.loading$, ctx.loading)
  const [formUrl] = useObservable<string>(ctx.formUrl$, ctx.formUrl)
  const [qrcode] = useObservable<string>(ctx.qrcode$, ctx.qrcode)

  const donateForm = useRef<HTMLDivElement>()

  useEffect(() => {
    const src$ = interval(200).pipe(
      filter(_ => isBrowser() && !!donateForm.current && ctx.useHashState),
      map(
        v =>
          donateForm.current.getBoundingClientRect().y + 
            (document.documentElement.scrollTop || document.body.scrollTop)
      ),
      share()
    )
    const ready$ = src$.pipe(
      bufferCount(2, 1),
      scan((acc, [prev, current]) => (prev === current ? ++acc : 0), 0),
      skipWhile(v => v < 20)
    )
    const sub = src$.pipe(
      takeUntil(ready$),
      distinctUntilChanged()
    ).subscribe(v => {
      if (donateForm.current) {
        Scroll.animateScroll.scrollTo(v-20)
      }
    })
    return () => sub?.unsubscribe()
  }, [])
  
  return (
    <Form ref={donateForm}>
      <FormHeader>
        Сделать пожертвование 
        {/* {apiAvailable 
          ? <span css={'color: lime;'}>•</span> 
          : <span css={'color: red;'}>•</span>
        } */}
      </FormHeader>
      <FormBody index={step}>
        <Step>
          <P>Для этого выберите сумму пожертвования:</P>
          <Table>
            {amountButtons.map(([id, value], index) => {
              return (
                <TableCell key={`k1-${index}`}>
                  <TableButton id={id} active={amount === value} onClick={() => setAmount(value)}>
                    {value} BYN
                  </TableButton>
                </TableCell>
              )
            })}
          </Table>
          <FlexRow css={'margin-bottom: 1em;'}>
            <Space />
            <L>Или введите свою:</L>{' '}
            <NumberInput
              value={amount}
              onValueChange={v => setAmount(v)}
              css={`
                border-color: ${darkRed1};
                width: 7em;
                text-align: center;
              `}
            />{' '}
            <L>BYN</L>
            <Space />
          </FlexRow>
        </Step>
        <Step>
          <P>
            Мы поминаем наших благодетелей, оставьте,
            <br />
            пожалуйста, нам Ваше имя:
          </P>
          <FlexRow>
            <Space />
            <L css={'margin-right: .75em'}>Мое имя:</L>{' '}
            <Input
              value={name}
              onValueChange={v => setName(v)}
              css={`
                color: ${darkBlue1};
                width: 10em;
                text-align: center;
              `}
            />
            <Space />
          </FlexRow>
          <FlexRow
            className={checkboxVisible ? 'visible' : ''}
            css={`
              margin-top: 1em;
              opacity: 0;
              transition: opacity 0.3s ease-in-out;
              &.visible {
                opacity: 1;
              }
            `}
          >
            <Space />
            <Checkbox value={isOrthodox} onValueChange={setIsOrthodox} css={'margin-right: .75em'} />{' '}
            <L css={'line-height: 1.8'}>
              Я являюсь крещеным
              <br />
              православным христианином.<span css={'color: ' + darkRed1}>*</span>
            </L>
            <Space />
          </FlexRow>
          <FlexRow
            className={checkboxVisible ? 'visible' : ''}
            css={`
              opacity: 0;
              transition: opacity 0.3s ease-in-out;
              &.visible {
                opacity: 1;
              }
            `}
          >
            <Space />
            <P>
              <small>
                <span css={'color: ' + darkRed1}>*</span>Это необходимо для того, чтобы мы знали, как Вас поминать.
              </small>
            </P>
            <Space />
          </FlexRow>
        </Step>
        <Step>
          <P>
            Выберите систему перевода денег,
            <br />
            которая Вам подходит:
          </P>
          <PaymentButtonGroup>
            {paymentMethods.map(([id, caption, svgPath1, svgPath2], index) => {
              return (
                <PaymentButton
                  key={`k2-${index}`}
                  id={id}
                  active={paymentMethod === id}
                  onClick={() => setPaymentMethod(id)}
                >
                  <img
                    src={paymentMethod === id ? svgPath2 : svgPath1}
                    css={`
                      width: 5em;
                      object-fit: contain;
                      margin-right: 2em;
                      opacity: 0.33;
                    `}
                  />
                  <L>{caption}</L>
                </PaymentButton>
              )
            })}
          </PaymentButtonGroup>
        </Step>
        <Step>
          {loading ? (
            <>
              <P>
                Создаем счет для пожертвования,
                <br />
                надо немного подождать...
              </P>
              <Spinner />
            </>
          ) : (
            <>
              <P>
                Мы создали для Вас счет для пожертвования,
                <br />
                теперь необходимо перейти на страницу
                <br />
                платежного сервиса, чтобы осуществить перевод <sup css={`color: ${darkRed1};`}>*</sup>.
              </P>
              <Button
                css={`
                  border-radius: 3px;
                  background: ${lightBlue1};
                  width: 50%;
                  margin: auto;
                  color: white;
                `}
                onClick={() => {
                  window.location.href = formUrl
                }}
              >{`Перейти →`}</Button>
              <div
                css={`
                  text-align: center;
                `}
              >
                <P><small>
                  <sup css={`color: ${darkRed1};`}>*</sup>
                  Платежный сервис принимает платежи только с белорусских карт.
                </small></P>
                <img src={CardTypesSvgPath} alt="" />
              </div>
            </>
          )}
        </Step>
        <Step>
          {loading ? (
            <>
              <P>
                Создаем счет для пожертвования,
                <br />
                надо немного подождать...
              </P>
              <Spinner />
            </>
          ) : (
            <>
              <P
                css={`
                  padding: 0 1.5em !important;
                  font-size: 0.85em !important;
                  text-align: justify !important;
                `}
              >
                Откройте приложение поддерживающие оплату по ЕРИП, выберите «Оплата по QR-коду», наведите камеру
                телефона на картинку ниже, Вас должно перевести на страницу с оплатой.
              </P>
              <P>
                <img
                  css={`
                    width: 12em;
                    height: 12em;
                    margin: auto;
                  `}
                  src={`data:image/png;base64,${qrcode}`}
                  alt=""
                />
              </P>
              <P
                css={`
                  padding: 0 1.5em !important;
                  line-height: 1;
                `}
              >
                <small
                  css={`
                    color: ${darkRed1};
                    line-height: 1;
                  `}
                >
                  Если что-то неполучилось, то Вы можете осуществить перевод по ЕРИП вручную, как описано ниже.
                </small>
              </P>
            </>
          )}
        </Step>
        <Step>
          <P>
            <img src={ErrorSvgPath} alt="" />
          </P>
          <P>
            Простите, произошла ошибка,
            <br />
            пожертвование не пришло...
          </P>
        </Step>
        <Step>
          <P>
            <img src={SuccessSvgPath} alt="" />
          </P>
          <P>
            Платежный сервис успешно переслал нам,
            <br />
            Ваше пожертвование!
          </P>
          <P
            css={`
              color: ${darkRed1};
            `}
          >
            Благодарим!
          </P>
        </Step>
      </FormBody>
      <FormPrev>
        <StepButton
          visible={!!prevCaption}
          disabled={prevDisabled}
          onClick={() => ctx.dispatch({ type: 'PREV_CLICK' })}
        >
          {prevCaption}
        </StepButton>
      </FormPrev>
      <FormProgress>
        <StepIndicator numberOfSteps={maxProgress} currentStep={stepToProgress.get(step)} />
      </FormProgress>
      <FormNext>
        <StepButton
          visible={!!nextCaption}
          disabled={nextDisabled}
          onClick={() => ctx.dispatch({ type: 'NEXT_CLICK' })}
        >
          {nextCaption}
        </StepButton>
      </FormNext>
    </Form>
  )
}

export default DonateForm
