import React from 'react'
import MapSvgPath from '~/assets/map.svg'

const MapView: React.FC = () => {
  return (
    <img
      src={MapSvgPath}
      alt="Расположения храма свт. Николая в д. Валерьяново"
      style={{
        width: `100%`
      }}
    />
  )
}

export default MapView