import React from 'react'
import styled from 'styled-components'
//@TODO: avoid react-scick
import Slider from 'react-slick'
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useStaticQuery, graphql } from 'gatsby'
import { headerFontFamily } from '~/styling'

const Container = styled.div`
  width: 100%;
  padding-bottom: calc(100% / 4 * 0.667);
`

const ThumbWrap = styled.div<{ year: number }>`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  &::after {
    position: absolute;
    top: calc(50% - 1em);
    left: 0;
    right: 0;
    bottom: 0;
    content: "${({ year }) => year}";
    text-align: center;
    color: rgba(255,255,255,.9);
    font: 600 2em/2em ${headerFontFamily};
  }
`

const DotsWrap = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  top: 100%;
  bottom: auto;
  li {
    flex: 1;
    width: initial;
    height: initial;
    box-sizing: border-box;
    margin: 0 10px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .slick-active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      border: .25em solid rgba(255, 255, 255, .7);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`

const BuildingHistorySlider: React.FC = () => {

  const data = useStaticQuery(graphql`
    query BuildingHistorySliderImages {
      img2010: file(relativePath: { eq: "img-2010.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2012: file(relativePath: { eq: "img-2012.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2015: file(relativePath: { eq: "img-2015.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2020: file(relativePath: { eq: "img-3-hram.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thumb2010: file(relativePath: { eq: "img-2010.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thumb2012: file(relativePath: { eq: "img-2012.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thumb2015: file(relativePath: { eq: "thumb-2015.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thumb2020: file(relativePath: { eq: "img-3-hram.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fluid(maxWidth: 180, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const thumbs = [
    {
      year: 2010,
      img: data.thumb2010.childImageSharp.fluid,
    },
    {
      year: 2012,
      img: data.thumb2012.childImageSharp.fluid,
    },
    {
      year: 2015,
      img: data.thumb2015.childImageSharp.fluid,
    },
    {
      year: 2020,
      img: data.thumb2020.childImageSharp.fluid,
    },
  ]

  const mainSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    // autoplay: true,
    // autoplaySpeed: 3000,
    infinite: true,
    initialSlide: 3,
    appendDots: (dots: any) => <DotsWrap>{dots}</DotsWrap>,
    customPaging: (i: number) => (
      <ThumbWrap year={thumbs[i].year}>
        <Img fluid={thumbs[i].img} />
      </ThumbWrap>
    ),
  }

  return (
    <Container>
      <Slider {...mainSettings}>
        <Img fluid={data.img2010.childImageSharp.fluid} />
        <Img fluid={data.img2012.childImageSharp.fluid} />
        <Img fluid={data.img2015.childImageSharp.fluid} />
        <Img fluid={data.img2020.childImageSharp.fluid} />
      </Slider>
    </Container>
  )
}

export default BuildingHistorySlider