import React, { HTMLAttributes, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { darkRed1, darkBlue1, lightBlue1, bodyFontFamily } from '~/styling'
import { isNull, inRange } from 'lodash-es'

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  value: string
  onValueChange: (value: string) => void
}

const StyledInput = styled.input.attrs({ type: 'text' })`
  padding: 0.25em;
  border: none;
  border-bottom: 1px solid ${lightBlue1};
  background: transparent;
  font: 1.4em/1.4em ${bodyFontFamily};
  font-style: italic;
`

const Input: React.FC<IInputProps> = ({ value, onValueChange, ...props }) => {
  function handler(e: React.ChangeEvent<HTMLInputElement>) {
    onValueChange(e.target.value)
  }
  return <StyledInput value={value} onChange={handler} {...props} />
}

interface INumberInputProps extends HTMLAttributes<HTMLInputElement> {
  value: number | null
  onValueChange: (value: number | null) => void
}

export const NumberInput: React.FC<INumberInputProps> = ({ value, onValueChange, ...props }) => {
  function handler(s: string) {
    for (let i = 0; i < s.length; i++) {
      if (!inRange(s.charCodeAt(i), 0x30, 0x40)) return
    }
    onValueChange(s ? parseInt(s, 10) : null)
  }
  return <Input value={!value ? '' : value.toString()} onValueChange={handler} {...props} />
}

export default Input