import React, { useState, useEffect, HTMLProps } from 'react'
import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'
import { useAppContext } from '~/providers'
import { useObservable } from '~/utils'

const BackgroundDiv: React.FC<HTMLProps<HTMLDivElement>> = ({ className }) => (
  <StaticQuery
    query={graphql`
      query QueryAbbotPhoto1 {
        imgAbbot: file(
          relativePath: { eq: "img-abbot.png" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200, maxHeight: 200, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        Tag="div"
        fluid={data.imgAbbot.childImageSharp.fluid}
        className={className}
      />
    )}
  />
)

const Container = styled.section`
  width: 100%;
  border: 1px solid #f6efe1;
`

const Card = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 5fr 7fr;
  grid-template-areas:
    'photo row1'
    'photo row2';
  background: #fefdfb;
  cursor: pointer;
`

const CardPhoto = styled(BackgroundDiv)`
  grid-area: photo;
  justify-self: stretch;
  align-self: stretch;
  margin: 1em 1em 0;
  min-height: 120px;
  min-width: 120px;
  max-height: 200px;
  max-width: 200px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`

const CardRow1 = styled.div`
  min-height: 3em;
  grid-area: row1;
  text-transform: uppercase;
  color: #a20101;
  line-height: 1.5;
  font-size: .85em;
  align-self: end;
  justify-self: stretch;
  display: flex;
  align-items: flex-end;
  justify-items: stretch;
  margin: 0 1em;
`

const CardRow2 = styled.div`
  min-height: 4.2em;
  grid-area: row2;
  align-self: center;
  justify-self: stretch;
  font-style: italic;
  font-size: 1em;
  line-height: 1.3;
  margin: 0 1em;
  small {
    font-size: 80%;
  }
`

const CardDetails = styled.div<{ active: boolean, height: number }>`
  overflow: hidden;
  border-top: 1px dashed #aaa;
  transition: height 0.4s ease-in-out, border-color 0.4s ease-in-out;
  height: ${props => props.height}px;
  ${props =>
    !props.active &&
    css`
      border-top: 1px dashed transparent !important;
      height: 0 !important;
    `}
`

const CollapseIcon = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px / 2);
  right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z' /%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${props =>
    props.active &&
    css`
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M16.59,5.41L15.17,4L12,7.17L8.83,4L7.41,5.41L12,10M7.41,18.59L8.83,20L12,16.83L15.17,20L16.58,18.59L12,14L7.41,18.59Z' /%3E%3C/svg%3E");
    `}
`

const AbbotInfo: React.FC = ({ children }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState(0)
  const detailsDiv = React.createRef<HTMLDivElement>()

  // console.log('render AbbotInfo')

  const appCtx = useAppContext()
  const [pageWidth] = useObservable(appCtx.pageWidth$, appCtx.pageWidth)

  useEffect(() => {
    const rect = detailsDiv.current.getBoundingClientRect()
    setHeight(rect.height)
  }, [appCtx.pageWidth]) 
  
  return (
    <Container>
      <Card onClick={() => setActive(!active)}>
        <CardPhoto></CardPhoto>
        <CardRow1>Настоятель прихода</CardRow1>
        <CardRow2>
          <small>иерей</small>
          <br />
          <span>Илья Иванович Курдюк</span>
        </CardRow2>
        <CollapseIcon active={active}></CollapseIcon>
      </Card>
      <CardDetails active={active} height={height}>
        <div ref={detailsDiv}>{children}</div>
      </CardDetails>
    </Container>
  )
}

export default AbbotInfo
