import { addDays, subDays, differenceInCalendarWeeks } from 'date-fns'

interface ScheduleEntry {
  time: string
  title: string
}

interface WeekDay {
  index: number
  date?: Date
  dayName: string
  title?: string
  services?: ScheduleEntry[]
}

export class Week {
  readonly start: Date
  readonly end: Date
  readonly title: string
  index: number
  days: WeekDay[] = [
    {
      index: 1,
      dayName: 'Понедельник'
    },
    {
      index: 2,
      dayName: 'Вторник'
    },
    {
      index: 3,
      dayName: 'Среда'
    },
    {
      index: 4,
      dayName: 'Четверг'
    },
    {
      index: 5,
      dayName: 'Пятница'
    },
    {
      index: 6,
      dayName: 'Суббота'
    },
    {
      index: 7,
      dayName: 'Воскресенье'
    }
  ]

  get monday() { return this.days[0] }
  get tuesday() { return this.days[1] }
  get wednesday() { return this.days[2] }
  get thursday() { return this.days[3] }
  get friday() { return this.days[4] }
  get saturday() { return this.days[5] }
  get sunday() { return this.days[6] }

  set monday(value: WeekDay) { this.days[0] = value }
  set tuesday(value: WeekDay) { this.days[1] = value }
  set wednesday(value: WeekDay) { this.days[2] = value }
  set thursday(value: WeekDay) { this.days[3] = value }
  set friday(value: WeekDay) { this.days[4] = value }
  set saturday(value: WeekDay) { this.days[5] = value }
  set sunday(value: WeekDay) { this.days[6] = value }

  constructor(start: Date, title: string) {
    this.start = start
    this.end = addDays(start, 7)
    this.title = title
    this.days = this.days.map((day: any) => {
      return {
        ...day,
        date: addDays(start, day.index),
      }
    })
  }
}

const PENTECOST_WEEKS = [
  'Светлая седмица',
  'Седмица 2-я по Пасхе',
  'Седмица 3-я по Пасхе',
  'Седмица 4-я по Пасхе',
  'Седмица 5-я по Пасхе',
  'Седмица 6-я по Пасхе',
  'Седмица 7-я по Пасхе',
]

const LENT_WEEKS = [
  '1-я приуготовительная седмица к Великому посту',
  '2-я приуготовительная седмица к Великому посту, о блудном сыне',
  '3-я приуготовительная седмица к Великому посту.',
  'Седмица 1-я Великого поста',
  'Седмица 2-я Великого поста',
  'Седмица 3-я Великого поста',
  'Седмица 4-я Великого поста, Крестопоклонная',
  'Седмица 5-я Великого поста',
  'Седмица 6-я Великого поста',
  'Страстная седмица',
]

function getNthAfterPentecostWeek(n: number) {
  return `Седмица ${n}-я по Пятидесятнице`
}

class OrthodoxYear {
  readonly year: number
  readonly ester: Date
  readonly nextEster: Date

  constructor(year: number) {
    this.year = year
    this.ester = OrthodoxYear.getPashaDate(year)
    this.nextEster = OrthodoxYear.getPashaDate(year + 1)
  }

  public *weeks() {
    const self = this
    let currentDate = self.ester
    const t1 = addDays(self.ester, 49)
    const t2 = subDays(self.nextEster, 70)

    while (currentDate < this.nextEster) {
      if (currentDate < t1) {
        yield new Week(
          currentDate,
          PENTECOST_WEEKS[differenceInCalendarWeeks(currentDate, self.ester)]
        )
      } else if (currentDate >= t2) {
        yield new Week(
          currentDate,
          LENT_WEEKS[differenceInCalendarWeeks(currentDate, t2)]
        )
        
      } else {
        yield new Week(
          currentDate,
          getNthAfterPentecostWeek(
            differenceInCalendarWeeks(currentDate, t1) + 1
          )
        )
      }
      currentDate = addDays(currentDate, 7)
    }
  }

  greatFeasts() {
    return [
      {title: 'Вход Господень в Иерусалим', date: subDays(this.ester, 7) },
      {title: 'Вознесение Господне', date: addDays(this.ester, 39)},
      {title: 'День Святой Троицы', date: addDays(this.ester, 49)},

      {title: 'Преображение Господне', date: this.fromNewStyleFixedDate(8, 19)},
      {title: 'Успение Пресвятой Богородицы', date: this.fromNewStyleFixedDate(8, 28)},
      {title: 'Рождество Пресвятой Богородицы', date: this.fromNewStyleFixedDate(9, 21)},

      {title: 'Воздвижение Креста Господня', date: this.fromNewStyleFixedDate(9, 27)},
      {title: 'Введение во храм Пресвятой Богородицы', date: this.fromNewStyleFixedDate(12, 4)},
      {title: 'Рождество Христово', date: this.fromNewStyleFixedDate(1, 7)},

      {title: 'Крещение Господне', date: this.fromNewStyleFixedDate(1, 19)},
      {title: 'Сретение Господне', date: this.fromNewStyleFixedDate(2, 15)},
      {title: 'Благовещение Пресвятой Богородицы', date: this.fromNewStyleFixedDate(4, 7)},
    ]
  }

  get ['Вербное']() { return subDays(this.ester, 7) }
  get ['Вознесение']() { return addDays(this.ester, 39) }
  get ['Троица']() { return addDays(this.ester, 49) }

  get ['Рождество Богородицы']() { return this.fromNewStyleFixedDate(9, 21) }
  get ['Воздвижение']() { return this.fromNewStyleFixedDate(9, 27) }
  get ['Введение во храм']() { return this.fromNewStyleFixedDate(12, 4) }
  get ['Рождество']() { return this.fromNewStyleFixedDate(1, 7) }
  get ['Крещение']() { return this.fromNewStyleFixedDate(1, 19) }
  get ['Сретение']() { return this.fromNewStyleFixedDate(2, 15) }
  get ['Благовещение']() { return this.fromNewStyleFixedDate(4, 7) }
  get ['Преображение']() { return this.fromNewStyleFixedDate(8, 19) }
  get ['Успение']() { return this.fromNewStyleFixedDate(8, 28) }

  static getPashaDate(year: number) {
    const a = year % 19
    const b = year % 4
    const c = year % 7
    const d = (19 * a + 15) % 30
    const e = (2 * b + 4 * c + 6 * d + 6) % 7
    const f = d + e
    const oldStyleDate = f > 9
      ? new Date(Date.UTC(year, 3, f - 9))
      : new Date(Date.UTC(year, 2, 22 + f))
    return addDays(oldStyleDate, 13)
  }

  protected fromNewStyleFixedDate(month: number, day: number) {
    return new Date(Date.UTC(this.year, month - 1, day))
  }

  static toNewStyle(date: Date) {
    return addDays(date, 13)
  }
}

export default OrthodoxYear