import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import useMeasure from '~/utils/useMeasure'
import { animated, useTransition } from 'react-spring'

const StyledSlepper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .3s ease-in-out;
`

const StyledStep = styled(animated.div)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`

interface IStepperProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number
}

const Stepper: React.FC<IStepperProps> = ({ children, index, style, ...props }) => {
  const items = children as React.ReactNode[]
  const [ ref, { height } ] = useMeasure<HTMLDivElement>()
  const [ prevIndex, set ] = useState(-1)
  useEffect(() => {
    if (index !== prevIndex) set(index)
  }, [index])
  const transitions = useTransition(index, i => i, {
    from: () => {
      if (prevIndex === -1) return { opacity: 1, transform: 'translate3d(0%,0,0)' }
      if (prevIndex > index) return { opacity: 0, transform: 'translate3d(-100%,0,0)' }
      return { opacity: 0, transform: 'translate3d(100%,0,0)' }
    },
    enter: () => {     
      return { opacity: 1, transform: 'translate3d(0%,0,0)' }
    },
    leave: () => {
      if (prevIndex > index) return { opacity: 0, transform: 'translate3d(100%,0,0)' }
      return { opacity: 0, transform: 'translate3d(-100%,0,0)' }
    },
  })
  return <StyledSlepper style={{...style, height: `${height}px`}} { ...props }>
    {transitions.map(({ item, props, key }) => {
        const child = items[item]
        return (
          <StyledStep ref={ref} key={`ss1-${key}`} style={props}>
            {child}
          </StyledStep>
        )
      })}
  </StyledSlepper>
}

export default Stepper