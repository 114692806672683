import React from 'react'
import styled from 'styled-components'

const ExclamationSpan = styled.span`
  position: relative;
  width: 50px;
  height: 50px;
  float: left;
  margin: 7px 10px 20px 0;
  background: #d6a33f;
  border-radius: 50px;
  &::after {
    position: absolute;
    content: '!';
    font-size: 32px;
    line-height: 46px;
    color: white;
    left: calc(50% - 6px);
  }
`

const ExclamationCircle: React.FC = ({ children }) => {
  return (
    <div>
      <ExclamationSpan />
      <span>
        {children}
      </span>
    </div>
  )
}

export default ExclamationCircle
