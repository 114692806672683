import React, { HTMLAttributes, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { darkRed1, lightBlue1, darkBlue1, bodyFontFamily } from '~/styling'
import { isNull, inRange } from 'lodash-es'

interface ICheckboxProps extends HTMLAttributes<HTMLDivElement> {
  value: boolean
  onValueChange: (value: boolean) => void
}

const StyledCheckbox = styled.div<{ value: boolean }>`
  position: relative;
  top: 0.35em;
  display: inline-block;
  width: 1.8em;
  height: 1.8em;
  border: 1px solid ${lightBlue1};
  background-size: contain;
  user-select: none;
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%233d81d6' d='M4.3 19.5l1.1-1.4 10 13.4L34.6 5.7l1.1 1.4-20.3 27.2z'/%3E%3C/svg%3E");
    user-select: none;
  }

  ${props =>
    props.value &&
    css`
      &::after {
        opacity: 1;
      }
    `}
`

const Checkbox: React.FC<ICheckboxProps> = ({ value, onValueChange, ...props }) => {
  function handler(e: React.MouseEvent<HTMLDivElement>) {
    onValueChange(!value)
  }
  return <StyledCheckbox value={value} onClick={handler} {...props} />
}

export default Checkbox